const { stringify } = require('query-string');
const uuid = require('node-uuid');

module.exports.generateLoginUrl = (clientId, destination) =>
    `${process.env.OIDC_BASE_URL}/connect/authorize?${stringify({
        authority: `${process.env.OIDC_BASE_URL}`,
        client_id: 'client-tool',
        redirect_uri: `${process.env.API_URL}/loginToken`,
        response_mode: 'form_post',
        response_type: 'id_token token code',
        scope: `openid email profile client-tool client-tool-api offline_access`,
        state: Buffer.from(JSON.stringify({ clientId, destination })).toString('base64'),
        nonce: uuid.v1()
    })}`;
