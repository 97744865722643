// @flow
'use strict';
const React = require('react');
const Header = require('../molecules/HeaderResponsive');
const Info = require('../atoms/Info');
const uuid = require('node-uuid');
const { stringify } = require('query-string');
const { generateLoginUrl } = require('../../utils');
const { PLATFORM_URL } = process.env;

function Splash(props: { state: { clientId: Guid, destination: string } }) {
    const state = JSON.parse(props.state);
    // const loginUrl = generateLoginUrl(state.clienId, state.destination);
    const loginUrl = PLATFORM_URL;
    return (
        <div>
            <Header />
            <div className="c-content">
                <div className="pure-g" style={{ margin: '0 auto', maxWidth: '64em' }}>
                    <div className="pure-u-1 pure-u-md-2-3">
                        <a href={loginUrl} className="login">
                            <h1>The Client Tool now runs on SBI Platform. Click here to log in.</h1>
                        </a>
                        <div>
                            <h3>
                                <p>Please note that password requirements have been changed.</p>
                                <p>
                                    All passwords must have at least:
                                    <ul>
                                        <li>8 characters</li>
                                        <li>one uppercase letter</li>
                                        <li>one lowercase letter</li>
                                        <li>one number</li>
                                        <li>one special character</li>
                                    </ul>
                                </p>
                            </h3>
                            <h3>
                                <p>
                                    If your password does not meet these requirements, please click{' '}
                                    <a href={`${PLATFORM_URL}/passwordreset`}>here</a> to reset your
                                    password
                                </p>
                            </h3>
                            <h3>
                                <p>
                                    If you still have issues please contact support with LiveChat.
                                </p>
                            </h3>
                        </div>
                    </div>
                    <div className="pure-u-1 pure-u-md-1-3">
                        <Info label="Phone">{'866.515.4909'}</Info>
                        <Info label="Email">{'support@sbiteam.com'}</Info>
                        <Info label="Address">
                            {`Small Business Innovations`}
                            <br />
                            {`2343 NE 21st Ave`}
                            <br />
                            {`Portland, OR 97212`}
                        </Info>
                    </div>
                </div>
            </div>
        </div>
    );
}

Splash.displayName = 'Splash';

module.exports = Splash;
